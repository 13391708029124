export const facialHairOptions = [{
    value: "Blank",
    label: "Sem nada",
}, {
    value: "BeardMedium",
    label: "Barba: media",
}, {
    value: "BeardLight",
    label: "Barba: fina",
}, {
    value: "BeardMajestic",
    label: "Barba: grossa",
}, {
    value: "MoustacheFancy",
    label: "Bigode: fino",
}, {
    value: "MoustacheMagnum",
    label: "Bigode: grosso",
}];