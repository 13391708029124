export const clothesOptions = [{
    value: "BlazerShirt",
    label: "Blazer e camisa",
}, {
    value: "BlazerSweater",
    label: "Blazer e sueter",
}, {
    value: "CollarSweater",
    label: "Sueter",
}, {
    value: "GraphicShirt",
    label: "Camisa com estampa",
}, {
    value: "Hoodie",
    label: "Moletom",
}, {
    value: "Overall",
    label: "Macacão",
}, {
    value: "ShirtCrewNeck",
    label: "Camisa",
}, {
    value: "ShirtScoopNeck",
    label: "Camisa gola larga",
}, {
    value: "ShirtVNeck",
    label: "Camisa gola V",
}];
